<template>
  <v-dialog v-model="dialog" width="500" >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="ml-3"
        dark
        @click.prevent.stop
        v-bind="attrs"
        v-on="on"
        v-if="!organization || !organization.id"
      >
        <v-icon>mdi-plus</v-icon></v-btn
      >
      <v-btn @click.prevent.stop color="primary" :icon="!btn" dark v-bind="attrs" v-on="on" v-else>
        <v-icon>mdi-circle-edit-outline</v-icon></v-btn
      >
    </template>

    <v-card>
      
      <v-card-title class="text-h5 grey lighten-2 mt-3 justify-space-between">
        <span v-if="!organization || !organization.id">Новая организация</span
        ><span v-else>Изменить</span>
        <v-btn fab @click="dialog = false" small
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-text-field
            :error-messages="errors.short_name ? errors.short_name : []"
            :error="errors.short_name ? true : false"
            @input="errors.short_name=false"
            v-model="orgForm.short_name"
            :counter="255"
            :rules="rules.required"
            label="Короткое наименование"
            required
          ></v-text-field>
          <v-textarea
                      :error-messages="errors.name ? errors.name : []"
            :error="errors.name ? true : false"
            @input="errors.name=false"
            v-model="orgForm.name"
            rows="3"
            :rules="rules.required"
            label="Полное наименование"
            required
          ></v-textarea>
          <v-text-field
            v-model="orgForm.inn"
            :counter="11"
            @input="checkInn()"
            :error="errors.inn  ? true : false"
            :error-messages="errors.inn ? errors.inn : []"
            :rules="rules.required"
            label="ИНН организации"
            required
          ></v-text-field>

          <v-text-field
            v-model="orgForm.director_name"
            :counter="255"
            :rules="rules.required"
            label="ФИО руководителя"
            required
          ></v-text-field>
          <v-text-field
            v-model="orgForm.lic_num"
            :counter="255"
            :rules="rules.required"
            label="Номер лицензии"
            required
          ></v-text-field>
          
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="orgForm.lic_date"
                  label="Дата выдачи лицензии"
                  prepend-icon="mdi-calendar"
                  :rules="rules.required"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="orgForm.lic_date"
                locale="ru-RU"
                :first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
            <v-autocomplete
            label="Регион"
            v-if="regions.length>0"
              v-model="orgForm.region_id"
              :items="regions"
              item-text="region_name"
              item-value="region_id"
            ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="saving"
          :loading="saving"
          @click="save()"
          >Сохранить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["organization","regions","btn"],
  data() {
    return {
      menu:false,
      dialog: false,
      saving: false,
      orgForm: {},
      rules: {
        required: [(v) => !!v || "Не должно быть пустым"],
      },
      errors:{}
    };
  },
  watch: {
    dialog(val) {
      if (val === true) {
        this.orgForm = { ...this.organization };
      }
    },
  },
  methods: {
    save() {
      this.$set(this,'saving',true)
      if (!this.$refs.form.validate() || !this.checkInn()) {
        this.saving = false;
        return false;
      }
      if (this.orgForm.id) {
        this.$http
          .post(
            "edu-org/update?id=" + this.orgForm.id,
            this.orgForm
          )
          .then((response) => {
            this.$emit("update", response.data);
            this.dialog = false;
          })
          .catch((e) => {
            this.errors=e.response.data
          })
          .finally(() => {
            this.$set(this,'saving',false)
          });
      } else {
        this.$http
          .post("edu-org/create", this.orgForm)
          .then((response) => {
            this.$emit("create", response.data);
            this.dialog = false;
          })
          .catch((e) => {
            this.errors=e.response.data
          })
          .finally(() => {
            this.$set(this,'saving',false)
          });
      }
    },
    checkInn() {
      this.errors.inn=false
      if (!this.is_valid_inn(this.orgForm.inn)) {
        this.errors.inn=["Проверьте ИНН, похоже что он введён некорректно"]
        return false;
      } else {
        return true;
      }
    },
    is_valid_inn(i) {
      if (!i) {
        return false;
      }
      if (i.match(/\D/)) return false;

      var inn = i.match(/(\d)/g);

      if (inn.length == 10) {
        return (
          inn[9] ==
          String(
            ((2 * inn[0] +
              4 * inn[1] +
              10 * inn[2] +
              3 * inn[3] +
              5 * inn[4] +
              9 * inn[5] +
              4 * inn[6] +
              6 * inn[7] +
              8 * inn[8]) %
              11) %
              10
          )
        );
      } else if (inn.length == 12) {
        return (
          inn[10] ==
            String(
              ((7 * inn[0] +
                2 * inn[1] +
                4 * inn[2] +
                10 * inn[3] +
                3 * inn[4] +
                5 * inn[5] +
                9 * inn[6] +
                4 * inn[7] +
                6 * inn[8] +
                8 * inn[9]) %
                11) %
                10
            ) &&
          inn[11] ==
            String(
              ((3 * inn[0] +
                7 * inn[1] +
                2 * inn[2] +
                4 * inn[3] +
                10 * inn[4] +
                3 * inn[5] +
                5 * inn[6] +
                9 * inn[7] +
                4 * inn[8] +
                6 * inn[9] +
                8 * inn[10]) %
                11) %
                10
            )
        );
      }

      return false;
    },
  }
};
</script>